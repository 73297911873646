export default {
    defaultPath: '/stories/',
    basename: '/admin', // only at build time to set,
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navFixedLayout: true,
    boxLayout: false,
    navListIcon: 'style1',
};
