import * as React from 'react';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import {getToken, loginWithJwt} from '../services/authService';

class ProtectedRoute extends React.Component {
    state = {
        loading: true,
        isAuthenticated: false,
    };

    componentDidMount() {
        const token = getToken();
        if (token !== null) {
            loginWithJwt(token).then((isAuthenticated) => {
                this.setState({
                    loading: false,
                    isAuthenticated,
                });
            });
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        const {component: Component, ...rest} = this.props;
        if (this.state.loading) {
            return <div/>;
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {!this.state.isAuthenticated &&
                        <Redirect push to="/login"/>}
                        <Component {...this.props} />
                    </div>
                )}
                />
            )
        }
    }
}

export default ProtectedRoute;