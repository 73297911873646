import http from './httpService';
import {apiUrl} from '../config.json';

const apiEndpoint = apiUrl + '/auth';
const tokenKey = 'token';

http.setToken(getToken());

export async function login(email, password) {
    const response = await http.post(apiEndpoint, {email, password});
    localStorage.setItem(tokenKey, response.data);
    return response.status === 200;
}

export async function loginWithJwt(token) {
    localStorage.setItem(tokenKey, token);
    const response = await http.get(`${apiEndpoint}/validate/${token}`);
    return response.status === 200;
}

export async function updatePassword(token, password) {
    const response = await http.patch(`${apiEndpoint}/update-password`, {
        token,
        password
    });
    return response.status === 200;
}

export async function sendResetPasswordLink() {
    const response = await http.post(`${apiEndpoint}/send-reset`);
    return response.status === 200;
}


export function logout() {
    localStorage.removeItem(tokenKey);
}

export function getToken() {
    if (localStorage.getItem(tokenKey) === null) {
        return null;
    } else {
        return localStorage.getItem(tokenKey);
    }
}

export default {
    login,
    loginWithJwt,
    logout,
    getToken,
};
