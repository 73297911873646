import axios from 'axios';
import {toast} from 'react-toastify';
import authService from './authService';
import 'react-toastify/dist/ReactToastify.css';

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (error.response !== undefined) {
        if (error.response.status === 401) {
            authService.logout();
            window.location = 'admin/login';
        }
        if (error.response.data === 'Invalid token.') {
            toast.error('Unauthorised update. Please try again');
        } else {
            toast.error(error.response.data);
        }
    } else if (error.response === undefined) {
        authService.logout();
        window.location = 'admin/login';
    } else if (!expectedError) {
        toast.error('An unexpected error occurred.');
    }
    return Promise.reject(error)
});

function setToken(token) {
    axios.defaults.headers.common['authorization'] = `${token}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json'
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
    setToken
};
