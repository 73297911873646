import React, {Component, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from '../hoc/_Aux';
import ProtectedRoute from './ProtectedRoute';
import {toast} from 'react-toastify';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

toast.configure();


const Login = React.lazy(() => import('../Pages/Authentication/Login/Login'));
const Reset = React.lazy(() => import('../Pages/Authentication/Reset/Reset'));
const PasswordResetLink = React.lazy(() => import('../Pages/Authentication/Reset/PasswordResetLink'));

class App extends Component {

    render() {
        return (
            <Aux>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route path="/login" component={Login}/>
                        <Route path="/reset" component={Reset}/>
                        <Route path="/resetPassword/:id" component={PasswordResetLink}/>
                        <ProtectedRoute path="/" component={AdminLayout}/>
                    </Switch>
                </Suspense>
            </Aux>
        );
    }
}

export default App;
